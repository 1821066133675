
<template>
<v-container>
    <div class="ma-12">
        <v-data-table :headers="headers" :items="colonias" sort-by="created_at" class="elevation-0 px-6 py-4">
            <!-- Header -->
            <template v-slot:top>
                <v-toolbar flat >
                    <v-toolbar-title>Colonias</v-toolbar-title>
                </v-toolbar>
            </template>
            <template v-slot:[`item.valor`]="{ item }">
                {{item.valor.toLocaleString('es-MX', { style: 'currency', currency: 'MXN',})}}
            </template>
            <template v-slot:[`item.tiempo_de_entrega`]="{ item }">
                {{item.tiempo_de_entrega}} minutos
            </template>
            <!-- Acciones por fila -->
            <template v-slot:[`item.actions`]="{ item }">
                <v-menu bottom left>
                    <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on">
                        <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                    </template>
                    <v-list style="font-size:13px;">
                        <v-list-item @click="editItem(item)">
                            <v-icon small class="mr-2">
                                mdi-pencil
                            </v-icon>
                            Editar
                        </v-list-item>
                        <v-list-item @click="deleteItem(item)">
                            <v-icon small class="mr-2">
                                mdi-delete
                            </v-icon>
                            Eliminar
                        </v-list-item>
                    </v-list>
                </v-menu>
            </template>
            <!-- Tabla sin información -->
            <template v-slot:no-data>
                No existen registros de colonias aún
            </template>
            <!-- Monto -->
            <template v-slot:[`item.amount`]="{ item }">
                {{item.amount.toLocaleString('es-MX', { style: 'currency', currency: 'MXN',})}}
            </template>
        </v-data-table>




        <!-- Crear colonia -->
        <v-dialog v-model="createDialog" max-width="900px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn bottom color="#f60e15" dark fab fixed right v-bind="attrs" v-on="on">
                <v-icon color="white">  mdi-plus </v-icon>
            </v-btn> 
          </template>
          <create @closeCreateDialogColonia="closeCreateDialogColonia"/>
        </v-dialog>
        <!-- Editar colonia -->
        <v-dialog v-model="editDialog" max-width="700px">
          <edit v-bind:colonia="colonia" @closeDialogEditColonia="closeDialogEditColonia"/>
        </v-dialog>
        <!-- Dialogo confirmación de eliminación -->
        <div class="text-center">
            <v-bottom-sheet  v-model="sheet" inset>
                <v-sheet class="text-center" height="150px">
                    <div class="pt-6">
                    ¿Seguro que deseas borrar esta colonia?
                    </div>
                    <v-btn class="mt-4" text color="error" @click="deleteColonia()">
                    Eliminar
                    </v-btn>
                    <v-btn class="mt-4" text color="grey" @click="cancel()">
                    Cancelar
                    </v-btn>
                </v-sheet>
            </v-bottom-sheet>
        </div>
    </div>
</v-container>
</template>

<script>
import axios from "axios";
import Create from "../components/colonia/create"
import Edit from "../components/colonia/edit"
export default {
    components: {
        'create':Create,
        'edit':Edit,
    }, 
    props:{
      search:String
    },
    data () {
      return {
            colonia:'',
            editDialog: false,
            createDialog: false,
            sheet: false,
            deleteId:''
      }
    },
    created(){
      this.$store.dispatch('colonia/getColonias')
      this.$store.dispatch('currentUser/getUser')
    },
    methods:{
        closeDialogEditColonia: function(params) {
            this.editDialog = false;
            this.$store.dispatch('colonia/getColonias')
        },
        closeCreateDialogColonia: function(params) {
            this.createDialog = false;
            this.$store.dispatch('colonia/getColonias')
        },
        deleteColonia(){
            axios.delete("https://torstdio.site/api/v1/colonia/borrar/"+this.deleteId).then(response => {
                this.deleteId = ''
                this.sheet = false
                this.$store.dispatch('colonia/getColonias')
            });
        },
        cancel(){
            this.deleteId = ''
            this.sheet = false
        },
        deleteItem (item) {
            this.deleteId = item.id
            this.sheet = true
        },
        editItem(item){
            this.colonia = this.$store.state.colonia.colonias.filter(colonia=>colonia.id == item.id)[0]
            this.editDialog = true
        },
    },
    computed:{
      headers(){ 
        return [
        { text: 'Nombre', value: 'nombre',},
        { text: 'Valor', value: 'valor' },
        { text: 'Tiempo de Entrega', value: 'tiempo_de_entrega',},
        { value: 'actions', sortable: false, align: 'end', },
      ]},
      currentUser:{
        get(){
          return this.$store.state.currentUser.user;
        }
      },
      colonias:{
        get(){
          var colonias = this.$store.state.colonia.colonias.filter(colonia=>colonia.sucursal == this.currentUser.sucursal)
          if(this.search!=''){
            colonias = colonias.filter(colonia =>colonia.nombre.toLowerCase().includes(this.search.toLowerCase()))
            return colonias
          }else{
            return colonias
          }
        }
      },
    },
}
</script>

<style>

</style>

<style>
    .v-data-table > .v-data-table__wrapper tbody tr.v-data-table__expanded__content {
        box-shadow: none!important;
        background: #f7f7f7;
    }
</style>